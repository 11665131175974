@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  height: 100%;
  margin: 0;
}

.rotate-0 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate-90 {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: rotate(90deg) translateY(-100%);
  width: 100vh;
  height: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate-180 {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center;
  transform: rotate(180deg);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate-270 {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: rotate(270deg) translateX(-100%);
  width: 100vh;
  height: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#detach-button-host {
  opacity: 0 !important;
}